
import { Component, Vue } from 'vue-property-decorator'
import { IssueDetail } from '@/types/inspectionReport'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
@Component
export default class DayStatisticalDetail extends Vue {
  private loading = false
  private list: Array<any> = []
  private pdf: any = null
  private downloading: any = null
  private pageIndex = 1
  private onePageCount = 8
  get dayInfo () {
    return this.$route.query || {}
  }

  get dateTime () {
    return this.dayInfo && this.dayInfo.patrolDate ? (this.dayInfo.patrolDate as string).replace(/(\d{4})-(\d{2})-(\d{2})/, '$1年$2月$3日') : ''
  }

  created (): void {
    if (this.dayInfo !== {}) {
      this.getList()
    } else {
      this.$router.back()
    }
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<{ list: Array<IssueDetail> }>(this.$apis.inspectionReport.selectDayIssuePage, {
      patrolType: this.dayInfo.patrolType,
      patrolDate: this.dayInfo.patrolDate
    }).then(res => {
      const list = res.list || []
      let ind = -1
      list.forEach((item: IssueDetail, index: number) => {
        const sInd = index % this.onePageCount
        if (sInd === 0) {
          ind++
          this.list.push([])
        }
        this.list[ind].push(item)
      })
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  }

  download () {
    this.downloading = this.$loading({
      lock: true,
      text: '文件下载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.pageCreate()
  }

  pageCreate () {
    this.pdf = new JsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a1',
      putOnlyUsedFonts: true,
      floatPrecision: 16 // or 'smart', default is 16
    })
    this.pageIndex = 1
    const element = document.getElementsByClassName('global-table') as HTMLCollectionOf<Element>
    const pageNum = element.length
    this.pageAdd(element || [], pageNum)
  }

  pageAdd (element: HTMLCollectionOf<Element>, pageNum: number) {
    html2canvas(element[this.pageIndex - 1] as HTMLElement, {
      allowTaint: false,
      useCORS: false,
      scale: 2,
      backgroundColor: '#ffffff'
    }).then(canvas => {
      const contentWidth = canvas.width
      const contentHeight = canvas.height
      // html页面生成的canvas在pdf中图片的宽高（a1纸的尺寸[594,841]）
      const imgWidth = 841
      const imgHeight = (841 / contentWidth) * contentHeight

      const pageData = canvas.toDataURL('image/jpeg', 1.0)
      this.pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
      if (this.pageIndex === pageNum) {
        this.pdf.save(this.dateTime + '巡查日报_' + this.dayInfo.patrolTypeName + '.pdf')
        this.downloading.close()
      } else if (this.pageIndex < pageNum) {
        this.pageIndex++
        this.pdf.addPage()
        this.pageAdd(element, pageNum)
      }
    })
  }
}
